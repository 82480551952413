.slider {
    flex: 1 1 75%;
}

.track {
    height: 12px;
    background-color: #BBF0EA;
}

.track-1 {
    height: 12px;
    background-color: #C4C4C4;
}

.thumb {
    outline: none;
    background-color: #6EE4D6;
    border: 4px solid #59BAAF;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    height: 32px;
    width: 32px;
    transform: translate(-50%, -50%);
    top: 5px;
    box-sizing: border-box;
}

.thumb.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.32);
}

.mark {
    width: 5px;
    height: 18px;
    background-color: #fff;
}
